import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { getExcerpt } from "../../utils/get-excerpt"

const CourseCard = ({ course }) => {
  //console.log(course)

  const excerpt = getExcerpt(course)

  const bgArray = ["bg-red-400", "bg-blue-400", "bg-teal-400"]

  const bgCard = bgArray[Math.floor(Math.random() * bgArray.length)]

  const sortedChapters = course.chapters.sort((a, b) => a.title.localeCompare(b.title))

  return (
    <motion.div
      key={course.id}
      className="overflow-hidden rounded border bg-white shadow-lg"
      whileHover={{ scale: 1.05 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <Link to={`/cours/${course.level.slug}/${course.slug}`}>
        <div className={`media relative ${bgCard} pb-2/3`}>
          {course.featured_media && (
            <div className="aspect-video w-full overflow-hidden object-cover">
              <Img
                fluid={course.featured_media.childImageSharp.fluid}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
                style={{
                  position: "none",
                }}
                placeholderClassName="test"
              />
            </div>
          )}
        </div>
        <hr
          style={{ height: "3px", marginTop: "-1px" }}
          className={`${bgCard}`}
        />
        <div className="px-6 py-4">
          <p className={"ext-sm flex items-center text-gray-600"}>
            {course.level.title}
          </p>
          <div className="mb-4 font-sans text-xl font-bold">{course.title}</div>
          <div className={`text-base text-gray-700`}>
            <ul className="pl-2 border-l-2 space-y-2">
              {sortedChapters.map(chapter => {
                return <li key={chapter.title} className="leading-tight">{chapter.title}</li>
              })}
            </ul>
          </div>
        </div>
      </Link>
    </motion.div>
  )
}

CourseCard.propTypes = {}

export default CourseCard
